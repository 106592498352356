import {Link} from "gatsby";
import React, {FC} from "react";
import cn from "classnames";

export interface SidebarItemProps {
    depth?: number;
    label: string;
    link?: string;
    items?: SidebarItemProps[];
    onClick?: () => void;
}

const SidebarItem: FC<SidebarItemProps> = ({label, link, items, depth, onClick}) => {
    const currentNode = link ? (
        <Link className="text-current" to={link} onClick={onClick}>
            {label}
        </Link>
    ) : (
        <span className="text-current">{label}</span>
    );

    return items && items.length ? (
        <>
            {currentNode}
            <SidebarList items={items} onItemClick={onClick} depth={(depth || 0) + 1} />
        </>
    ) : (
        currentNode
    );
};

interface BackdropProps {
    isVisible?: boolean;
    onClick?: () => void;
}

const Backdrop: FC<BackdropProps> = ({isVisible, onClick}) => {
    const baseClasses = "fixed inset-0 bg-black z-overlay";
    const animationClasses = "transform-gpu lg:transform-none transition-opacity duration-500 ease-in-out lg:hidden";
    const visibilityClasses = isVisible ? "opacity-30" : "opacity-0 pointer-events-none";
    const allClasses = cn(baseClasses, animationClasses, visibilityClasses);
    return <div className={allClasses} onClick={onClick} />;
};

export interface SidebarProps {
    items: SidebarItemProps[];
    depth?: number;
    className?: string;
    isVisible?: boolean;
    onBackdropClick?: () => void;
    onItemClick?: () => void;
}

const SidebarList: FC<SidebarProps> = ({items, depth, onItemClick}) => {
    const textClasses = depth === 0 ? "text-xl" : "text-base";
    const paddingClasses = depth === 0 ? "px-8 my-6" : "px-4 my-4";
    const borderClasses = depth === 0 ? "" : "border-gray-600 border-solid border-solid border-l-2";
    return (
        <ul className={cn(paddingClasses, textClasses, borderClasses)}>
            {items.map((item) => (
                <li key={item.label}>
                    <SidebarItem {...item} depth={depth} onClick={onItemClick} />
                </li>
            ))}
        </ul>
    );
};

const Sidebar: FC<SidebarProps> = ({items, isVisible, className, onBackdropClick, onItemClick, depth = 0}) => {
    const visibilityClasses = isVisible ? "translate-x-full" : "";
    const allClasses = cn("sidebar", visibilityClasses, className);
    return (
        <>
            <Backdrop isVisible={isVisible} onClick={onBackdropClick} />
            <aside className={allClasses}>
                <SidebarList items={items} depth={depth} onItemClick={onItemClick} />
            </aside>
        </>
    );
};

export default Sidebar;
