import React, {FC, useState} from "react";
import cn from "classnames";
import MainNav from "./main-nav";
import FabButton from "../fab-button";
import Sidebar, {SidebarItemProps} from "./sidebar";
import PageFooter from "./page-footer";

interface LayoutProps {
    sidebarItems: SidebarItemProps[];
    className?: string;
}

const Layout: FC<LayoutProps> = ({children, className, sidebarItems}) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const hideSidebar = () => setSidebarVisible(false);
    const toggleSidebar = () => setSidebarVisible(!sidebarVisible);
    return (
        <>
            <MainNav />
            <div className="min-h-screen flex flex-col">
                <main className={cn("main flex flex-column", className)}>
                    <Sidebar
                        items={sidebarItems}
                        isVisible={sidebarVisible}
                        onBackdropClick={hideSidebar}
                        onItemClick={hideSidebar}
                    />
                    <div className="flex-1 flex flex-col overflow-y-auto">{children}</div>
                    <FabButton onToggle={toggleSidebar} isOn={sidebarVisible} />
                </main>
                <PageFooter />
            </div>
        </>
    );
};

export default Layout;
