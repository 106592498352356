import React, {FC} from "react";

interface FabButtonProps {
    onToggle: () => void;
    isOn: boolean;
}

const FabButton: FC<FabButtonProps> = ({onToggle, isOn}) => (
    <button
        onClick={onToggle}
        className="z-fab fixed bottom-4 right-4 w-16 h-16 rounded-full border-white border-solid border-2 bg-gray-900 text-white block lg:hidden shadow-lg"
    >
        {isOn ? (
            <svg
                width="24"
                height="24"
                fill="none"
                className="absolute top-1/2 left-1/2 -mt-3 -ml-3 transition duration-300 transform"
            >
                <path
                    d="M6 18L18 6M6 6l12 12"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        ) : (
            <svg
                width="24"
                height="24"
                fill="none"
                className="absolute top-1/2 left-1/2 -mt-3 -ml-3 transition duration-300 transform"
            >
                <path d="M4 8h16M4 16h16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )}
    </button>
);

export default FabButton;
