import React, {FC} from "react";
import Code from "../../components/code";
import Layout from "../../components/layout/layout-sidebar";
import Seo from "../../components/seo";
import {sidebarItemsGettingStarted} from "../../data/sidebar-items-getting-started";

const SideEffectsPage: FC = () => (
    <Layout sidebarItems={sidebarItemsGettingStarted}>
        <Seo
            title="Getting started with side effects"
            description="Instructions for performing side effects when working with a grid."
        />
        <section className="p-8">
            <h1>{`Side effects`}</h1>
            <p>{`The side effects module contains functions to perform side effects on a grid like traversing a grid without manipulating it.`}</p>
            <Code code={codeExample1} language="javascript" />
        </section>
    </Layout>
);

export default SideEffectsPage;

const codeExample1 = `import {createGridFromArray2D} from "gridl/core";
import {forEachCell} from "gridl/sideEffects";

const grid = createGridFromArray2D([
    [1, 2, 3],
    [4, 5, 6],
]);

let str = "";
forEachCell(grid, (cellValue) => {
    str = \`\${str}\${cellValue}\`;
});
// => str === "123456"`;
