import {SidebarItemProps} from "../components/layout/sidebar";
import {gettingStartedSlug} from "../helpers/slug-helper";

export const sidebarItemsGettingStarted: SidebarItemProps[] = [
    {
        label: "Basics",
        items: [
            {
                label: "Data structure",
                link: gettingStartedSlug("grid-data-structure"),
            },
            {
                label: "Selectors",
                link: gettingStartedSlug("grid-selectors"),
            },
            {
                label: "Transformers",
                link: gettingStartedSlug("grid-transformers"),
            },
            {
                label: "Search & Find",
                link: gettingStartedSlug("searching-cell-values-in-a-grid"),
            },
            {
                label: "Walkers",
                link: gettingStartedSlug("grid-walkers"),
            },
            {
                label: "Reducers",
                link: gettingStartedSlug("grid-reducers"),
            },
            {
                label: "Side effects",
                link: gettingStartedSlug("grid-side-effects"),
            },
        ],
    },
    // {
    //     label: "Advanced topics",
    //     items: [
    //         {
    //             label: "Custom selectors",
    //             link: gettingStartedSlug("custom-selectors"),
    //         },
    //         {
    //             label: "Custom transformers",
    //             link: gettingStartedSlug("custom-transformers"),
    //         },
    //         {
    //             label: "Using sub grids",
    //             link: gettingStartedSlug("using-sub-grids"),
    //         },
    //         {
    //             label: "gridl + redux",
    //             link: gettingStartedSlug("gridl-and-redux"),
    //         },
    //     ],
    // },
];
